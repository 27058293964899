<template>
  <div class="navbar-container bg p-0">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->

      <div>
        <!-- <img
          src="/Low/icon/no.svg"
          alt="notify"
          height="35"
        > -->
        <i
          class="fas fa-bars"
          style="font-size: 40px; color: #ffffff"
        />
      </div>

      <div class="m-auto">
        <b-img
          src="/logo/logo.png"
          width="100"
        />
      </div>

      <div>
        <!-- <i
          class="fas fa-sign-out-alt"
          style="font-size: 40px; color: #ffffff"
          @click="logout"
        /> -->

        <!-- <div
          class="icn-login"

        >
          ออกจากระบบ
        </div> -->
        <!-- <img
          v-b-modal.modal-xl
          src="/Low/icon/me.svg"
          alt="notify"
          height="35"
        > -->
        <!-- <i
          v-b-modal.modal-xl
          class="fas fa-bars fa-2x"
          style="color: #fff"
        /> -->
      </div>

      <b-modal
        id="modal-xl"
        hide-footer
        centered
        size="xs"
        title="Extra Large Modal"
        hide-header
      >
        <div class="text-center">
          <router-link :to="`/login?ag=${userData.agent_id}`">
            <b-button
              variant="gradient-warning"
              pill
              @click="logout"
            >
              <feather-icon
                icon="LogInIcon"
                class="mr-50"
              />
              <span class="align-middle">ออกจากระบบ</span>
            </b-button>
          </router-link>
        </div>
      </b-modal>
    </div>
    <div class="navbar-container bg2 p-0">
      <!-- Nav Menu Toggler -->
      <div class="container pd">
        <span> <i
          class="fas fa-user-circle text-white"
          style="font-size: 15px;"
        />&nbsp;{{
          userData.username
        }}</span>

        <!-- Right Col -->
        <b-navbar-nav class="nav align-items-center ml-auto">
          <!-- <user-dropdown /> -->

          <div>
            <!-- <i class="fas fa-sync" /> -->
            <span>
              <img
                src="/iconNew/icon-เล็กด้านล่าง-02.png"
                alt="deposit"
                height="25"
                style="margin-top: -5px;"
              >&nbsp;{{

                Number(Math.abs(balance.balance))
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),

              }}
            </span>
            <!-- {{ UserData.username }} -->
            <span
              style="font-size: 0.95rem"
              @click="refresh"
            ><i
              class="fad fa-redo-alt text-white"
              style="font-size: 15px"
            />
            </span>
          </div>
          <!-- <router-link :to="{ name: 'auth-login' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-warning"
            pill
          >
            <feather-icon
              icon="LogInIcon"
              class="mr-50"
            />
            <span class="align-middle">เข้าสูระบบ</span>
          </b-button>
          @click="logout"
        </router-link> -->
        </b-navbar-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { BNavbarNav, BImg } from 'bootstrap-vue'
// import UserDropdown from './components/UserDropdown.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    // Navbar Components
    BImg,
    // BLink,
    // UserDropdown,
    BNavbarNav,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      balance: 0,
    }
  },
  mounted() {
    // this.openModal()
    this.getbalance(this.userData.username)
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('CountLogin')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push('/login')
    },
    refresh() {
      this.getbalance(this.userData.username)
    },
    getbalance(username) {
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          this.balance = response.data.data
          if (this.balance.balance < 0) {
            this.balance.balance = 0
          }
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 600px;
}
.pd {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  color: #fff;
}

i {
  color: #ffc400;
}
.icn-login {
    padding: 5px 10px;
    background: linear-gradient(to bottom, #e7d39d, #b19560);
    color: black;
    border-radius: 5em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}
/* .neon {
  margin-top: -12px;
  font-family: 'Helvethaica', sans-serif;

  border-radius: 5px;
  padding: 15px 10px;
} */
</style>
